import {
  Colors,
} from '@bluecurrent/web-component-lib';

import Url from './Links.config';

export default [
  {
    id: 1,
    title: ['appDownload', { ns: 'ui' }],
    onClick: () => window.open(Url().BLUE_CURRENT_APP, '_self'),
    href: Url.BLUE_CURRENT_APP,
    align: 'right',
    icon: {
      name: 'Mobile',
      height: 19,
      width: 19,
      color: Colors.BLUE,
      iconSet: 'FA',
    },
  },
  {
    id: 2,
    title: ['myDashboard', { ns: 'ui' }],
    onClick: () => window.open(Url().BLUE_CURRENT_MY, '_blank'),
    href: Url.BLUE_CURRENT_MY,
    align: 'right',
    icon: {
      name: 'User',
      height: 18,
      width: 18,
      color: Colors.BLUE,
      iconSet: 'FA',
    },
  },
];
