import * as React from 'react';
import axios from 'axios';

const useSanityData = (data) => {
  const query = encodeURIComponent(data);

  const [response, setResponse] = React.useState([]);

  React.useEffect(() => {
    axios.get(`https://${process.env.SANITY_PROJECT_ID}.api.sanity.io/v2021-06-07/data/query/production?query=${query}`)
      .then(
        (res) => {
          setResponse(res.data.result);
        },
      )
      .catch((error) => console.log('[BCU useSanityData]: ', error));
  }, [query]);

  return response;
};

export default useSanityData;
