import * as React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import {
  Lang,
} from '@bluecurrent/web-component-lib';

import 'react-loading-skeleton/dist/skeleton.css';

import Setup from '../src/config/Setup.config';

import handleLanguageDetect from '../src/functions/handleLanguageDetect';

import Hubspot from '../src/services/Hubspot/cookieBanner';

import Layout from '../src/components/Layout';

import '../src/styles/css/Global.css';
import '../src/styles/css/Components.css';
import '../src/styles/css/Hubspot.css';

function MyApp({ Component, pageProps }) {
  const { i18n } = Lang.useTranslation();
  const router = useRouter();

  /* Detect language & switch if necessary */
  React.useEffect(() => {
    handleLanguageDetect(i18n, router.query.l);

    if (Setup.PSEUDO && process.env.NODE_ENV === 'development') {
      i18n.changeLanguage('pseudo');
    }
  }, [router]);

  return (
    <>
      <Head>
        <script>
          {
            `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-T4GL4D9');
            `
          }
        </script>
      </Head>
      <noscript>
        <iframe
          title="googletagmanager"
          src="https://www.googletagmanager.com/ns.html?id=GTM-T4GL4D9"
          height="0"
          width="0"
          style={{
            display: 'none',
            visibility: 'hidden',
          }}
        />
      </noscript>
      <Hubspot />
      <Layout>
        { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
        <Component {...pageProps} />
      </Layout>
    </>
  );
}

export default MyApp;
