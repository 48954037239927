const getBranches = (tree) => `
  *[_type == "websiteTrees" && tree == "${tree}" ]|order(orderRank) {
    _id,
    slug,
    tree,
    config {
      title,
      description,
      overview,
      href,
      logo_suffix,
      title_suffix,
      link,
      image {
        'src': src.asset->url,
        size,
      },
      align,
      divider,
      tags,
    },
    orderRank
  }
`;

export default getBranches;
