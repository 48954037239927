import Url from './Links.config';

export default [
  {
    id: 0,
    title: ['requestChargePointBusiness', { ns: 'ui' }],
    href: Url().REQUEST_CHARGE_POINT_BUSINESS,
    target: '_self',
  },
  {
    id: 1,
    title: ['requestChargePointForAtHome', { ns: 'ui' }],
    href: Url().REQUEST_CHARGE_POINT_AT_HOME,
    target: '_self',
  },
  {
    id: 2,
    title: ['requestFreeChargePoint', { ns: 'ui' }],
    href: Url().REQUEST_FREE_EXPLOITATION_CHARGE_POINT,
    target: '_self',
  },
];
