import * as React from 'react';

import {
  Colors,

  useDimensions,

  MainContainer,

  Text,
} from '@bluecurrent/web-component-lib';

export default function GlobalSystemNotificationBanner(props) {
  const size = useDimensions();

  const ref = React.useRef();

  React.useEffect(() => {
    if (ref.current !== undefined) props.setHeight(ref.current.clientHeight);
  }, [
    ref,
    props.isVisible,
    size,
  ]);

  return (
    <div
      ref={ref}
      style={{
        backgroundColor: Colors.ORANGE,
        height: 'auto',
        paddingTop: 7.5,
        paddingBottom: 7.5,
        position: 'fixed',
        top: props.top,
        zIndex: 2147483002,
        width: '100%',
        visibility: props.isVisible ? 'visible' : 'hidden',
        transition: 'top .2s ease-in-out',
      }}
    >
      <MainContainer>
        <Text
          fontSize="1em"
          color={Colors.WHITE}
          textAlign="center"
        >
          {props.text}
        </Text>
      </MainContainer>
    </div>
  );
}
