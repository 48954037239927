import Setup from '../config/Setup.config';
import Language from '../config/Language.config';

import useStorage from '../hooks/useStorage';

import handleChangeLanguage from './handleChangeLanguage';

/**
 * Detect which language to use on first load.
 * This can be from the browser or from localStorage.
 *
 * Also when the project is on development and PSEUDO is enabled
 * in the config we initialize it here.
 *
 * @param {Object} i18n i18n object from Lang.useTranslation hook.
 * @param {string} query url parameter that defines the language iso - optional
 */
export default function handleDetectLanguage(i18n, query) {
  const storage = useStorage();

  /* Browser navigator language */
  // const navigatorLanguage = navigator.language || navigator.userLanguage;

  const handleLanguageSupport = (input) => {
    let language = Setup.FALLBACK_LANG;

    if (Language.some((item) => input === item.iso)) {
      language = Language.find((item) => item.iso === input).iso;
    }

    return language;
  };

  /* Change the language to PSEUDO if set in config and on development */
  if (Setup.PSEUDO && process.env.NODE_ENV === 'development') {
    i18n.changeLanguage('pseudo');
  }

  if (query !== undefined) {
    /*
      Check if in the url a language is defined and prioritize
      that language over other languages.
    */
    handleChangeLanguage(i18n, handleLanguageSupport(query));
  } else if (storage.getItem('lang') === undefined) {
    /*
     Check if previous language is saved in localStorage & check browser settings
    */
    // const splittedNavigatorLanguage = navigatorLanguage.split('-')[0];

    // handleChangeLanguage(i18n, handleLanguageSupport(splittedNavigatorLanguage));
    handleChangeLanguage(i18n, 'nl');
  }

  handleChangeLanguage(i18n, storage.getItem('lang'));
}
