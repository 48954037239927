import * as React from 'react';

import {
  Colors,
  Lang,

  Text,
} from '@bluecurrent/web-component-lib';

import quickAccess from '../../../../config/QuickAccess.config';

import Item from '../Item';

export default function QuickAccessBanner() {
  const { _ } = Lang.useTranslation();

  return (
    <div
      style={{
        flex: '1 1 auto',
        width: '100%',
        backgroundColor: `${Colors.GREEN}20`,
        borderLeft: `solid 2px ${Colors.GREEN}15`,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 25,
        paddingBottom: 15,
      }}
    >
      <Text
        color={Colors.GREEN}
        fontSize="1.1em"
        fontWeight={700}
      >
        {_('leaveItToMe', { ns: 'ui' })}
      </Text>
      <div
        style={{
          marginTop: 7.5,
        }}
      >
        <Text
          fontSize="1em"
          color={Colors.GREEN}
        >
          {_('youCanDoThisOnline', { ns: 'ui' })}
        </Text>
      </div>
      <div
        style={{
          marginTop: 15,
        }}
      >
        {
          quickAccess.map((item) => (
            <React.Fragment
              key={item.id}
            >
              <Item
                title={Array.isArray(item.title) ? _(...item.title) : item.title}
                href={item.href}
                target={item.target}
              />
            </React.Fragment>
          ))
        }
      </div>
    </div>
  );
}
