import * as React from 'react';
import { useRouter } from 'next/router';
import { AnimatePresence, motion } from 'framer-motion';

import {
  Colors,
  Lang,
  Breakpoint,
  useDimensions,
  getScrollHeight,
  getScrollDirection,
  getColorScheme,
  GlobalHeader,
  Header,
} from '@bluecurrent/web-component-lib';

import Language from '../../../config/Language.config';
import Pages from '../../../config/Pages.config';
import HeaderLinks from '../../../config/Header.config';
import Setup from '../../../config/Setup.config';

import useStorage from '../../../hooks/useStorage';
import useSanityData from '../../../hooks/useSanityData';

import getGlobalSystemNotificationSettings from '../../../services/Sanity/query/getGlobalSystemNotificationSettings';

import GlobalSystemNotificationBanner from '../../Modules/GlobalSystemNotificationBanner';

export default function HeaderWrap() {
  const { _, i18n } = Lang.useTranslation();
  const size = useDimensions();
  const scrollHeight = getScrollHeight();
  const scrollDirection = getScrollDirection();
  const router = useRouter();
  const storage = useStorage();
  const globalSystemNotificationSettings = useSanityData(
    getGlobalSystemNotificationSettings(),
  );

  const [globalHeader, setGlobalHeader] = React.useState([]);
  const [subMenus, setSubMenus] = React.useState([]);
  const [
    isGlobalSystemNotificationBannerVisible,
    setGlobalSystemNotificationBannerVisible,
  ] = React.useState(false);
  const [
    globalSystemNotificationBannerHeight,
    setGlobalSystemNotificationBannerHeight,
  ] = React.useState(0);

  const [isDropdownVisible, setDropdownVisible] = React.useState(false);

  const language = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;

  const renderLanguageList = () => Language.map((lang) => ({
    key: lang.iso,
    title: _(lang.iso, { ns: 'settings' }),
    type: 'radio',
    selected: i18n.language === lang.iso,
    onClick: () => {
      i18n.changeLanguage(lang.iso);
      storage.setItem('lang', lang.iso);
    },
  }));

  const languageListArray = [
    {
      id: 0,
      title: _(i18n.language, { ns: 'settings' }),
      icon: {
        name: 'Language',
        height: 23,
        width: 23,
        color: Colors.BLUE,
        iconSet: 'FA',
      },
      dropdownMenu: renderLanguageList(),
      align: 'left',
    },
  ];

  const handleDropdownVisibility = (value) => {
    setDropdownVisible(value);
  };

  React.useEffect(() => {
    setGlobalSystemNotificationBannerVisible(
      globalSystemNotificationSettings[0]?.boolean,
    );
  }, [globalSystemNotificationSettings]);

  React.useEffect(() => {
    setGlobalHeader([]);

    // Combine language array with header array
    setGlobalHeader(languageListArray.concat(HeaderLinks));
  }, [i18n.language]);

  React.useEffect(() => {
    const initialPage = Pages[0];
    let array = [];

    /* Loop to get subMenu on the correct set in array */
    const loop = (subMenu, tree, index) => {
      if (index === Pages.length - 1) {
        setSubMenus(array);
        return;
      }

      Promise.all([subMenu]).then((value) => {
        if (value[0]) {
          /* Find branches for specific tree */
          const findTreeBranches = value.find((i) => i[0].tree === tree);

          array = [
            ...array,
            findTreeBranches.map((e) => ({
              ...e,
              title: e?.title?.[language] ?? undefined,
              href: e?.href ?? undefined,
              description: {
                text: e?.description?.text?.[language][0] ?? undefined,
                portableText: e.description.portableText,
              },
              link: {
                title: e?.link?.title?.[language] ?? undefined,
              },
              logoSuffix: e?.logoSuffix?.[language] ?? undefined,
              suffix: e?.suffix?.[0]?.text && {
                text: e?.suffix?.[0]?.text?.[language] ?? undefined,
                colorScheme: e.suffix?.[0]?.colorscheme,
              },
              image: e?.image?.src && {
                src: e?.image?.src ?? undefined,
                size: e?.image?.size,
              },
              align: e?.align ?? undefined,
              divider: e?.divider ?? undefined,
            })),
          ];

          loop(Pages[index + 1].subMenu, Pages[index + 1].tree, index + 1);
        } else {
          /* SubMenu is not defined so return undefined */
          array = [...array, undefined];

          loop(Pages[index + 1].subMenu, Pages[index + 1].tree, index + 1);
        }
      });
    };

    loop(initialPage.subMenu, initialPage.tree, 0);
  }, [language]);

  return (
    <>
      <GlobalSystemNotificationBanner
        isVisible={isGlobalSystemNotificationBannerVisible}
        text={globalSystemNotificationSettings[0]?.text}
        setHeight={(height) => setGlobalSystemNotificationBannerHeight(height)}
        top={
          !isDropdownVisible
          && scrollDirection === 'down'
          && scrollHeight > 45
            ? -globalSystemNotificationBannerHeight
            : 0
        }
      />
      <AnimatePresence>
        {size.width >= Breakpoint.lg && (
          <motion.div
            style={{
              position: 'fixed',
              width: '100%',
              top:
                !isDropdownVisible
                && scrollDirection === 'down'
                && scrollHeight > 45
                  ? isGlobalSystemNotificationBannerVisible
                    ? 0
                    : -45
                  : isGlobalSystemNotificationBannerVisible
                    ? globalSystemNotificationBannerHeight
                    : 0,
              transition: 'top .2s ease-in',
            }}
            initial={{
              zIndex: 2147483002,
            }}
            animate={{
              zIndex: size.width >= Breakpoint.lg ? 2147483004 : 2147483002,
            }}
            exit={{
              zIndex: 2147483002,
            }}
            transition={{
              delay: size.width >= Breakpoint.lg ? 0.4 : 0,
            }}
          >
            <GlobalHeader
              items={globalHeader.map(
                ({
                  id,
                  title,
                  icon,
                  dropdownMenu,
                  align,
                  suffix,
                  onClick,
                }) => ({
                  id,
                  title: Array.isArray(title) ? _(...title) : title,
                  icon,
                  dropdownMenu,
                  align,
                  suffix,
                  onClick,
                }),
              )}
              onChangeDropdown={handleDropdownVisibility}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <div
        style={{
          position: 'fixed',
          zIndex: size.width >= Breakpoint.lg ? 2147483003 : 2147483004,
          top:
            size.width >= Breakpoint.lg
              ? !isDropdownVisible
                && scrollDirection === 'down'
                && scrollHeight > 45
                ? isGlobalSystemNotificationBannerVisible
                  ? 45
                  : 0
                : isGlobalSystemNotificationBannerVisible
                  ? 45 + globalSystemNotificationBannerHeight
                  : 45
              : isGlobalSystemNotificationBannerVisible
                ? !isDropdownVisible
                  && scrollDirection === 'down'
                  && scrollHeight > 45
                  ? 0
                  : globalSystemNotificationBannerHeight
                : 0,
          width: '100%',
          transition: 'top .2s ease-in',
        }}
      >
        <Header
          logoURL="/"
          logoTarget="_self"
          logoSubfix={false}
          items={Pages.map(
            (
              {
                id,
                title,
                type,
                onClick,
                href,
                colorScheme,
                icon,
                header,
                sideNav,
                component,
                subMenu,
                subMenuBanner,
              },
              index,
            ) => ({
              id,
              title: Array.isArray(title) ? _(...title) : title,
              type,
              onClick,
              href,
              colorScheme,
              icon: {
                name: icon.name,
                height: icon.height,
                width: icon.width,
                color:
                  router.pathname.startsWith(href)
                  && href !== '/'
                  && router.pathname !== '/'
                    ? getColorScheme(colorScheme).vibrant
                    : Colors.MEDIUM_GREY,
                iconSet: icon.iconSet,
              },
              active: href === router.pathname,
              header,
              sideNav,
              component,
              subMenu:
                subMenu !== undefined
                && (Array.isArray(subMenu) ? subMenu : subMenus[index]),
              subMenuBanner,
            }),
          )}
          links={HeaderLinks.map(
            ({
              id, title, icon, suffix, onClick, href,
            }) => ({
              id,
              title: Array.isArray(title) ? _(...title) : title,
              icon: {
                name: icon.name,
                width: icon.width,
                height: icon.height,
                color: Colors.GREY,
              },
              href,
              suffix,
              onClick,
            }),
          )}
          topButton={{
            title: _(i18n.language, { ns: 'settings' }),
            icon: {
              name: 'Language',
              width: 25,
              height: 25,
              color: Colors.MEDIUM_GREY,
              iconSet: 'FA',
            },
            dropdownMenu: renderLanguageList(),
          }}
        />
      </div>
    </>
  );
}
