class Storage {
  static exemptions = ['token'];

  static getStore() {
    const currentObject = JSON.parse(localStorage.getItem('bluecurrent.ww-state')) ?? {};

    return currentObject.cookies_accepted === 'true' ? localStorage : sessionStorage;
  }

  static setItem(key, value) {
    const storage = Storage.getStore();

    const currentObject = JSON.parse(storage.getItem('bluecurrent.ww-state')) ?? {};

    currentObject[key] = value;

    storage.setItem('bluecurrent.ww-state', JSON.stringify(currentObject));
  }

  static getItem(key) {
    const storage = Storage.getStore();

    const currentObject = JSON.parse(storage.getItem('bluecurrent.ww-state')) ?? {};

    return currentObject[key];
  }

  static removeItem(key) {
    const storage = Storage.getStore();

    const currentObject = JSON.parse(storage.getItem('bluecurrent.ww-state')) ?? {};

    delete currentObject[key];

    storage.setItem('bluecurrent.ww-state', JSON.stringify(currentObject));
  }

  static clear() {
    const storage = Storage.getStore();

    storage.removeItem('bluecurrent.ww-state');
    storage.removeItem('token');
  }

  static setOriginalState(key, value) {
    Storage.getStore().setItem(key, value);
  }

  static getOriginalState(key) {
    return Storage.getStore().getItem(key);
  }

  static removeOriginalStateItem(key) {
    Storage.getStore().removeItem(key);
  }

  static copySessionToLocal() {
    const currentObject = sessionStorage.getItem('bluecurrent.ww-state');

    localStorage.setItem('bluecurrent.ww-state', currentObject);

    Storage.exemptions.forEach((i) => {
      const item = sessionStorage.getItem(i);
      if (item) localStorage.setItem(i, item);
    });

    sessionStorage.clear();
  }
}

const useStorage = () => Storage;

export default useStorage;
