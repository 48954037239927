import axios from 'axios';

export default async function handleFormattingTreeSubMenu(data) {
  const query = encodeURIComponent(data);
  let array = [];

  await axios.get(`https://${process.env.SANITY_PROJECT_ID}.api.sanity.io/v2021-06-07/data/query/production?query=${query}`)
    .then(
      (res) => {
        array = res.data.result;
      },
    )
    .catch((err) => console.log(err));

  return array.map((item) => ({
    /* eslint-disable-next-line no-underscore-dangle */
    id: item._id,
    title: item.config?.title,
    href: `/${item.tree.toLowerCase()}/${item.slug}`,
    logoSuffix: item.config?.logo_suffix,
    description: {
      text: item.config?.overview,
      portableText: true,
    },
    suffix: item.config?.tags,
    link: {
      title: item.config?.link?.text === 'custom'
        ? item.config?.link?.custom_text
        : item.config?.link?.text,
    },
    image: {
      src: item.config?.image.src,
      size: item.config?.image?.size,
    },
    align: item.config?.align,
    divider: item.config?.divider,
    tree: item.tree,
  }));
}
