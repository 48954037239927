import useStorage from '../hooks/useStorage';

/**
 * Change the language in the entire project.
 *
 * @param {Object} i18n i18n object from Lang.useTranslation hook.
 * @param {string} iso Language to change to in iso format.
 */
export default function handleChangeLanguage(i18n, iso) {
  const storage = useStorage();

  i18n.changeLanguage(iso);
  storage.setItem('lang', iso);
}
