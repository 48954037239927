// import * as React from 'react';

// import {
//   Lang,
// } from '@bluecurrent/web-component-lib';

export default function Hubspot() {
// const { _, i18n } = Lang.useTranslation();

  // React.useEffect(() => {
  //   const script = document.createElement('script');
  //
  //   script.setAttribute('src', 'https://js-eu1.hs-scripts.com/26281745.js');
  //   script.setAttribute('id', 'hubspot-script-loader');
  //
  //   document.body.appendChild(script);
  //
  //   script.onload = () => {
  //     setTimeout(() => {
  //       const iframeContainer = document.getElementById('hubspot-messages-iframe-container');
  //       const banner = document.getElementById('hs-eu-cookie-confirmation');
  //       const bannerInner = document.getElementById('hs-eu-cookie-confirmation-inner');
  //       const acceptButton = document.getElementById('hs-eu-confirmation-button');
  //       const declineButton = document.getElementById('hs-eu-decline-button');
  //       const closeButton = document.getElementById('hs-eu-close-button');
  //       const closeButtonContainer = document.getElementById('hs-eu-close-button-container');
  //
  //       const confirmationInner = document.querySelector('#hs-eu-cookie-confirmation-inner');
  //       let innerChildParagraph = null;
  //       if (confirmationInner !== null) {
  //         innerChildParagraph = confirmationInner.querySelectorAll('p');
  //       }
  //
  //       if (iframeContainer !== null) {
  //         iframeContainer.style.setProperty('z-index', '2147483646', 'important');
  //       }
  //
  //       /* Banner */
  //       if (banner !== null) {
  //         banner.style.setProperty('z-index', '2147483647', 'important');
  //       }
  //
  //       if (bannerInner !== null) {
  //         bannerInner.style.setProperty('padding', '0px 0px 0px 0px', 'important');
  //       }
  //
  //       /* Accept Button */
  //       if (acceptButton !== null) {
  //         acceptButton.style.setProperty('font-weight', '500', 'important');
  //         acceptButton.style.setProperty('border', 'solid 2px #0098DB', 'important');
  //         acceptButton.style.setProperty('margin', '0px', 'important');
  //         acceptButton.style.setProperty('font-size', '17px', 'important');
  //       }
  //
  //       /* Decline Button */
  //       if (declineButton !== null) {
  //         declineButton.style.setProperty('font-weight', '500', 'important');
  //         declineButton.style.setProperty('border', 'solid 2px #0098DB', 'important');
  //         declineButton.style.setProperty('margin', '10px 0px 0px 0px', 'important');
  //         declineButton.style.setProperty('font-size', '17px', 'important');
  //       }
  //
  //       /* Close Button */
  //       if (closeButton !== null && closeButtonContainer !== null) {
  //         const bannerTitle = document.getElementById('BlueCurrentCookieBannerTitle');
  //
  //         closeButton.style.setProperty('font-size', '25px', 'important');
  //         closeButton.style.setProperty('color', '#CCCCCC', 'important');
  //         closeButton.style.setProperty('font-weight', '300', 'important');
  //
  //         // Prepare Container for Title
  //         closeButtonContainer.style.setProperty('display', 'flex', 'important');
  //         closeButtonContainer.style.setProperty
  //         ('justify-content', 'space-between', 'important');
  //         closeButtonContainer.style.setProperty('align-items', 'center', 'important');
  //         closeButtonContainer.style.setProperty('height', '30px', 'important');
  //         closeButtonContainer.style.setProperty('margin', '0px 0px 5px 0px', 'important');
  //
  //         /* Text */
  //         if (innerChildParagraph !== null) {
  //           innerChildParagraph.forEach((item) => {
  //             item.style.setProperty('font-weight', '300', 'important');
  //             item.style.setProperty('font-size', '16px', 'important');
  //             item.style.setProperty('line-height', '16px', 'important');
  //           });
  //         }
  //
  //         // Add Title
  //         if (bannerTitle === null) {
  //           const title = document.createElement('p');
  //           const titleContent = document.createTextNode(_('cookie.title', { ns: 'ui' }));
  //           title.appendChild(titleContent);
  //           title.setAttribute('id', 'BlueCurrentCookieBannerTitle');
  //           title.style.setProperty('text-align', 'left', 'important');
  //           title.style.setProperty('margin', '0px 0px 0px 0px', 'important');
  //           title.style.setProperty('font-weight', '500', 'important');
  //           title.style.setProperty('font-family', 'Museo-Sans', 'important');
  //           title.style.setProperty('font-size', '17px', 'important');
  //
  //           const parent = closeButton.parentNode;
  //           parent.insertBefore(title, closeButton);
  //         } else {
  //           bannerTitle.innerHTML = _('cookie.title', { ns: 'ui' });
  //           bannerTitle.style.setProperty('font-weight', '500', 'important');
  //         }
  //       }
  //     }, 100);
  //   };
  // }, [i18n.language]);

  return null;
}
