import * as React from 'react';

import {
  Breakpoint,

  useDimensions,
} from '@bluecurrent/web-component-lib';

import Header from './Sections/Header';
import Footer from './Sections/Footer';

export default function Layout(props) {
  const size = useDimensions();

  const [hasFooter, setFooter] = React.useState(true);

  React.useEffect(() => {
    if (window.location.pathname.startsWith('/forms/')) setFooter(false);
  }, []);

  return (
    <div
      style={{
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          height: size.width < Breakpoint.lg
            ? 80
            : 125,
        }}
      >
        <Header />
      </div>
      <main>
        {props.children}
      </main>
      {
        hasFooter && (
          <Footer />
        )
      }
    </div>
  );
}
