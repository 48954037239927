import * as React from 'react';

import getBranches from '../services/Sanity/query/getBranches';

import handleFormattingTreeSubMenu from '../functions/handleFormattingTreeSubMenu';

import QuickAccessBanner from '../components/Sections/Header/QuickAccessBanner';
// import BusinessBanner from '../components/Sections/Header/BusinessBanner'; See comment

export default [
  {
    id: 0,
    title: ['home', { ns: 'ui' }],
    type: 'link',
    sideNav: true,
    header: false,
    href: '/',
    onClick: () => window.open('/', '_self'),
    colorScheme: 'black',
    icon: {
      name: 'Minus',
      width: 25,
      height: 25,
      iconSet: 'FA',
    },
  },
  {
    id: 1,
    title: ['business', { ns: 'ui' }],
    type: 'link',
    sideNav: true,
    header: true,
    colorScheme: 'blue',
    icon: {
      name: 'Office',
      width: 23,
      height: 23,
      iconSet: 'FA',
    },
    tree: 'business',
    route: '/business/[slug]',
    // subMenuBanner: (<BusinessBanner />), Will be added later when Mark has delivered content
    subMenu: handleFormattingTreeSubMenu(getBranches('business')),
  },
  {
    id: 2,
    title: ['atHome', { ns: 'ui' }],
    type: 'link',
    sideNav: true,
    header: true,
    href: '/home',
    onClick: () => window.open('/home', '_self'),
    colorScheme: 'yellow',
    icon: {
      name: 'HouseAlt',
      width: 23,
      height: 23,
      iconSet: 'FA',
    },
  },
  {
    id: 3,
    title: ['products', { ns: 'ui' }],
    type: 'link',
    sideNav: true,
    header: true,
    colorScheme: 'black',
    icon: {
      name: 'UMOVE',
      width: 27,
      height: 27,
      iconSet: 'BlueCurrent',
    },
    tree: 'products',
    route: '/products/[slug]',
    subMenuBanner: (<QuickAccessBanner />),
    subMenu: handleFormattingTreeSubMenu(getBranches('products')),
  },
  {
    id: 4,
    title: ['about', { ns: 'ui' }],
    type: 'link',
    sideNav: true,
    header: true,
    href: '/about',
    onClick: () => window.open('/about', '_self'),
    colorScheme: 'orange',
    icon: {
      name: 'Leaf',
      width: 23,
      height: 23,
      iconSet: 'FA',
    },
  },
  {
    id: 5,
    title: ['contact', { ns: 'ui' }],
    type: 'link',
    sideNav: true,
    header: true,
    href: '/contact',
    onClick: () => window.open('/contact', '_self'),
    colorScheme: 'green',
    icon: {
      name: 'Headset',
      width: 23,
      height: 23,
      iconSet: 'FA',
    },
  },
  {
    id: 6,
    title: ['getQuote', { ns: 'ui' }],
    type: 'button',
    sideNav: true,
    header: true,
    href: '/get-a-quote',
    onClick: () => window.open('/get-a-quote', '_self'),
    colorScheme: 'green',
    icon: {
      name: 'PenSquare',
      width: 23,
      height: 23,
      iconSet: 'FA',
    },
  },
];
