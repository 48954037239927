import * as React from 'react';

import {
  Colors,

  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

import handleLocalUrlChange from '../../../functions/handleLocalUrlChange';

export default function Item(props) {
  const [isHover, setHover] = React.useState(false);

  return (
    <a
      href={handleLocalUrlChange(props.href)}
      target={props.target}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      className="Hover"
      style={{
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: Colors.GREEN,
      }}
    >
      <div
        style={{
          height: 25,
          width: 22.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon
          name="Circle"
          type="solid"
          height={7.5}
          width={7.5}
          color={Colors.GREEN}
          iconSet="FA"
        />
      </div>
      <div
        style={{
          width: '100%',
          paddingLeft: 5,
        }}
      >
        <Text
          fontSize="1em"
          lineHeight="1.3"
          color={Colors.GREEN}
          textDecoration={isHover && 'underline'}
        >
          {props.title}
        </Text>
      </div>
    </a>
  );
}
