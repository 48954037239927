import * as React from 'react';

import {
  Lang,

  Footer,
} from '@bluecurrent/web-component-lib';

import Setup from '../../../config/Setup.config';
import Url from '../../../config/Links.config';

export default function FooterWrap() {
  const { _, i18n } = Lang.useTranslation();

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;

  return (
    <Footer
      text={_('slogan', { ns: 'branding' })}
      contact
      subsidy
      links={[
        {
          id: 0,
          text: _('faq', { ns: 'ui' }),
          href: Url(lang).FAQ,
          target: '_blank',
        },
        {
          id: 1,
          text: _('workAt', { ns: 'ui' }),
          href: Url().CAREERS,
          target: '_blank',
        },
        {
          id: 2,
          text: _('invest.invest', { ns: 'branding' }),
          href: Url().BLUE_CURRENT_INVEST,
          target: '_blank',
        },
      ]}
      subItems={[
        {
          id: 0,
          text: _('privacyPolicy', { ns: 'legal' }),
          href: Url().PRIVACY_POLICY,
          target: '_self',
        },
        {
          id: 1,
          text: _('termsConditions', { ns: 'legal' }),
          href: Url().TERMS_AND_CONDITIONS,
          target: '_self',
        },
      ]}
    />
  );
}
